<template>
  <GifLoading />
  <SuccessModal />
  <ErrorModal />
  <router-view />
</template>

<script>
import GifLoading from "@/components/loading/GifLoading.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import ErrorModal from "@/components/modals/ErrorModal.vue";
export default {
  name: "App",
  components: { GifLoading, SuccessModal, ErrorModal },
  created() {
    const token = localStorage.getItem("accessToken");
    if (token) {
      this.$store.dispatch("fetchRoles");
    }
  }
};
</script>
