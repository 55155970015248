import moment from "moment";

export function snakeToHuman(snakeStr) {
  return snakeStr
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function formatDate(date) {
  if (date == null) {
    return date;
  }
  return moment(String(date)).format("DD/MM/YYYY");
}

export function formatDatetime(datetime) {
  if (datetime == null) {
    return datetime;
  }
  return moment(String(datetime)).format("DD/MM/YYYY H:mm:ss");
}

export function formatInt(int) {
  if (typeof int !== "number") {
    return int;
  }
  return int.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatFloat(int) {
  if (typeof int !== "number") {
    return int;
  }
  return int.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function toSingular(word) {
  if (word.endsWith("ies")) {
    return word.slice(0, -3) + "y";
  } else if (word.endsWith("es")) {
    const exceptions = [
      "shoes",
      "hoses",
      "gases",
      "fuses",
      "diseases",
      "phases",
      "bases",
      "cases",
      "roles",
      "Roles",
      "Types",
      "types",
      "Field Types",
      "field types",
    ];
    if (exceptions.includes(word)) {
      return word.slice(0, -1);
    }
    return word.slice(0, -2);
  } else if (word.endsWith("s") && !word.endsWith("ss")) {
    return word.slice(0, -1);
  }
  return word;
}

export function toPlural(singular) {
  // Basic rules for pluralization
  if (singular.endsWith("y")) {
    // If a noun ends with ‘y’, replace ‘y’ with ‘ies’
    return singular.replace(/y$/, "ies");
  } else if (
    singular.endsWith("s") ||
    singular.endsWith("sh") ||
    singular.endsWith("ch") ||
    singular.endsWith("x") ||
    singular.endsWith("z")
  ) {
    // If a noun ends with ‘s’, ‘sh’, ‘ch’, ‘x’, or ‘z’, add ‘es’
    return singular + "es";
  } else {
    // In most other cases, just add ‘s’
    return singular + "s";
  }
}

export const column_types = [
  "int",
  "float",
  "datetime",
  "date",
  "string",
  "text",
  "boolean",
  "coords",
  "coords2",
  "_id",
  "relation",
  "image",
  "regex",
  "array",
];

export const field_types = [
  "int",
  "float",
  "datetime",
  "date",
  "string",
  "text",
  "quill",
  "boolean",
  "coords",
  "coords2",
  "_id",
  "relation",
  "image",
  "regex",
  "checkboxes",
  "MeterType",
  "selectMultible",
  "static_factor_dropdown_options",
  "operation_dropdown_options",
];
