<template>
  <button class="btn btn-danger px-5 text-white" :disabled="disabled" :type="type">
    {{ msg }}
  </button>
</template>
<script>
export default {
  name: "DangerBtn",
  props: {
    msg: { type: String, default: "submit" },
    type: { type: String, default: "submit" },
    disabled: { type: Boolean, default: false },
  },
};
</script>
