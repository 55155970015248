import { eventBus } from "@/events/eventBus";
import AuthStore from "@/stores/AuthStore";

export class ApiService {
  constructor(baseUrl = process.env.VUE_APP_BACKEND_URL) {
    this.baseUrl = baseUrl;
  }

  async request(endpoint, options = {}) {
    try {
      eventBus.emit("show-loading", true);
      const response = await fetch(endpoint, options);
      eventBus.emit("show-loading", false);
      if (response.ok) {
        if (response.status == 204) {
          return 1;
        }
        return await response.json();
      } else if (response.status == 422) {
        return { status: 422, errors: await response.json() };
      } else {
        const errorResponse = await response.json();
        switch (response.status) {
          case 400:
            eventBus.emit("open-error-modal", "Bad Request");
            break;
          case 401:
            AuthStore.dispatch("logout");
            eventBus.emit("open-error-modal", "Unauthorized");
            break;
          case 403:
            eventBus.emit("open-error-modal", "Forbidden");
            break;
          case 404:
            eventBus.emit("open-error-modal", "URL Not Found");
            break;
          case 429:
            eventBus.emit("open-error-modal", "Too Many Requests");
            break;
          case 500:
            eventBus.emit("open-error-modal", errorResponse);
            break;
          default:
            eventBus.emit("open-error-modal", "An unknown error occurred");
        }
        eventBus.emit("show-loading", false);
      }
    } catch (error) {
      eventBus.emit("open-error-modal", error);
      eventBus.emit("show-loading", false);
    }
  }

  async get(endpoint, filters = {}) {
    const url = new URL(`${this.baseUrl}/${endpoint}`);
    Object.keys(filters).forEach((key) => {
      const value = filters[key];
      if (value != null && value != "") {
        if (Array.isArray(value)) {
          value.forEach((item) => url.searchParams.append(`${key}[]`, item));
        } else {
          url.searchParams.append(key, value);
        }
      }
    });
    return this.request(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "accept-language": "en",
        Authorization: AuthStore.state.token,
      },
    });
  }

  async post(endpoint, data = null) {
    return this.request(new URL(`${this.baseUrl}/${endpoint}`), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "accept-language": "en",
        Authorization: AuthStore.state.token,
      },
      body: JSON.stringify(data),
    });
  }

  async put(endpoint, data = null) {
    const formData = new FormData();
    formData.append("_method", "PUT");

    if (typeof data === "object" && data !== null) {
      for (const [key, value] of Object.entries(data)) {
        if (key === "field_type") {
          formData.append("field_type", JSON.stringify(value));
        } else if (key === "dropdown_options" && Array.isArray(value)) {
          value.forEach((option, index) => {
            formData.append(
              `dropdown_options[${index}]`,
              JSON.stringify(option)
            );
          });
        } else {
          if (Array.isArray(value)) {
            formData.append(key, JSON.stringify(value));
          } else if (typeof value === "object" && value !== null) {
            formData.append(key, JSON.stringify(value));
          } else {
            formData.append(key, value);
          }
        }
      }
    }

    return this.request(new URL(`${this.baseUrl}/${endpoint}`), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "accept-language": "en",
        Authorization: AuthStore.state.token,
      },
      body: formData,
    });
  }

  async patch(endpoint) {
    return this.request(new URL(`${this.baseUrl}/${endpoint}`), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "accept-language": "en",
        "X-HTTP-Method-Override": "PATCH",
        Authorization: AuthStore.state.token,
      },
    });
  }

  async delete(endpoint) {
    return this.request(new URL(`${this.baseUrl}/${endpoint}`), {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "accept-language": "en",
        Authorization: AuthStore.state.token,
      },
    });
  }

  async upload(file) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch(`${this.baseUrl}/users/upload`, {
      method: "POST",
      headers: {
        Authorization: AuthStore.state.token,
      },
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      return data.url;
    } else {
      throw new Error("Failed to upload image");
    }
  }
}
