import "bootstrap/dist/css/bootstrap.css";
import "@/assets/styles/main.scss";
import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router/index";
import VueSelect from "vue-select";
import VOtpInput from "vue3-otp-input";
import "vue-select/dist/vue-select.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { QuillEditor } from "@vueup/vue-quill";
import {
  snakeToHuman,
  formatDate,
  formatDatetime,
  formatInt,
  formatFloat,
  toSingular,
  toPlural,
} from "@/helpers/text";
import AuthStore from "@/stores/AuthStore";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

const app = createApp(App)
  .component("v-otp-input", VOtpInput)
  .component("v-select", VueSelect)
  .component("VueDatePicker", VueDatePicker)
  .component("QuillEditor", QuillEditor)
  .use(router)
  .use(AuthStore)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    },
  });

app.config.globalProperties.$snakeToHuman = snakeToHuman;
app.config.globalProperties.$formatDate = formatDate;
app.config.globalProperties.$formatDatetime = formatDatetime;
app.config.globalProperties.$formatInt = formatInt;
app.config.globalProperties.$formatFloat = formatFloat;
app.config.globalProperties.$toSingular = toSingular;
app.config.globalProperties.$toPlural = toPlural;
app.mount("#app");
import "bootstrap/dist/js/bootstrap.js";
