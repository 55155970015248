import { eventBus } from "@/events/eventBus";
import { ApiService } from "@/services/ApiService";
import { createStore } from "vuex";

const state = {
  status: "",
  token: localStorage.getItem("accessToken") || "",
  roles: [],
};

const mutations = {
  auth_request(state) {
    state.status = "loading";
  },
  auth_success(state, { token, roles }) {
    state.status = "success";
    state.token = token;
    state.roles = roles;
  },
  auth_error(state) {
    state.status = "error";
  },
  logout(state) {
    state.status = "";
    state.token = "";
    state.roles = [];
  },
};

const actions = {
  async login({ commit }, form) {
    commit("auth_request");
    try {
      const api = new ApiService();
      const resp = await api.post("users/auth/login", form);
      if (resp && !resp.errors) {
        const token = resp.data.token;
        const roles = resp.data.roles;
        localStorage.setItem("accessToken", token);
        localStorage.setItem("roles", JSON.stringify(roles));
        commit("auth_success", { token, roles });
        return 1;
      } else {
        return resp;
      }
    } catch (err) {
      eventBus.emit("open-error-modal", err.message || "Login failed");
      commit("auth_error");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("roles");
      throw err;
    }
  },

  async fetchRoles({ commit }) {
    const api = new ApiService();
    try {
      const resp = await api.get("users/auth/roles");
      if (resp && !resp.errors) {
        const roles = resp.data;
        const token = localStorage.getItem("accessToken");
        localStorage.setItem("roles", JSON.stringify(roles));
        commit("auth_success", {
          token,roles
        });
      }
    } catch (err) {
      commit("auth_error");
      localStorage.removeItem("roles");
    }
  },

  async logout({ commit }) {
    const api = new ApiService();
    await api.post("users/logout");
    commit("logout");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("roles");
  },
};

const getters = {
  hasPermission: (state) => (permissionId) => {
    return state.roles.some((role) =>
      role.permissions.some((permission) => permission.id === permissionId)
    );
  },
};

export default createStore({
  state,
  mutations,
  actions,
  getters,
});
