import AuthStore from "@/stores/AuthStore";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/auth",
    component: () => import("@/components/layouts/AuthLayout.vue"),
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("@/views/auth/LoginView.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        name: "home",
        beforeEnter: (to, from, next) => {
          AuthStore.dispatch("fetchRoles").then(() => {
            if (
              AuthStore.getters.hasPermission(1) ||
              AuthStore.getters.hasPermission(5)
            ) {
              next();
            } else {
              next("/clients");
            }
          });
        },
        component: () => import("@/views/main/HomeView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "formSettings",
        name: "formSettings",
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          AuthStore.dispatch("fetchRoles").then(() => {
            if (AuthStore.getters.hasPermission(3)) {
              next();
            } else {
              next("/not-authorized");
            }
          });
        },
        children: [
          {
            path: "countries",
            name: "countries",
            component: () =>
              import("@/views/main/formSettings/CountriesView.vue"),
          },
          {
            path: "cities",
            name: "cities",
            component: () => import("@/views/main/formSettings/CitiesView.vue"),
          },
          {
            path: "field_types",
            name: "field_types",
            component: () =>
              import("@/views/main/formSettings/FieldTypesView.vue"),
          },
          {
            path: "terms",
            name: "terms",
            component: () => import("@/views/main/formSettings/TermsView.vue"),
          },
          {
            path: "policies",
            name: "policies",
            component: () =>
              import("@/views/main/formSettings/PoliciesView.vue"),
          },
          {
            path: "sources",
            name: "sources",
            component: () =>
              import("@/views/main/formSettings/SourcesView.vue"),
          },
          {
            path: "staticFactors",
            name: "staticFactors",
            component: () =>
              import("@/views/main/formSettings/StaticFactorsView.vue"),
          },
          {
            path: "operations",
            name: "operations",
            component: () =>
              import("@/views/main/formSettings/OperationsView.vue"),
          },
          {
            path: "categories",
            name: "categories",
            component: () =>
              import("@/views/main/formSettings/CategoriesView.vue"),
          },
          {
            path: "subcategories",
            name: "subcategories",
            component: () =>
              import("@/views/main/formSettings/SubcategoriesView.vue"),
          },
          {
            path: "form_groups",
            name: "form_groups",
            component: () =>
              import("@/views/main/formSettings/FormGroupsView.vue"),
          },
          {
            path: "form_builder/:id",
            name: "form_builder",
            component: () =>
              import("@/views/main/formSettings/FormBuilderView.vue"),
          },
          {
            path: "meterTypes",
            name: "meterTypes",
            component: () =>
              import("@/views/main/formSettings/MeterTypesView.vue"),
          },
          {
            path: "units",
            name: "units",
            component: () => import("@/views/main/formSettings/UnitsView.vue"),
          },
          {
            path: "register_forms",
            name: "register_forms",
            component: () =>
              import("@/views/main/formSettings/RegisterFormsView.vue"),
          },
        ],
      },
      {
        path: "authentication",
        name: "authentication",
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          AuthStore.dispatch("fetchRoles").then(() => {
            if (AuthStore.getters.hasPermission(4)) {
              next();
            } else {
              next("/not-authorized");
            }
          });
        },
        children: [
          {
            path: "users",
            name: "users",
            component: () =>
              import("@/views/main/authentication/UsersView.vue"),
          },
          {
            path: "permissions",
            name: "permissions",
            component: () =>
              import("@/views/main/authentication/PermissionsView.vue"),
          },
          {
            path: "roles",
            name: "roles",
            component: () =>
              import("@/views/main/authentication/RolesView.vue"),
          },
        ],
      },
      {
        path: "clients",
        name: "clients",
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          AuthStore.dispatch("fetchRoles").then(() => {
            if (
              AuthStore.getters.hasPermission(2) ||
              AuthStore.getters.hasPermission(5) ||
              AuthStore.getters.hasPermission(6)
            ) {
              next();
            } else {
              next("/not-authorized");
            }
          });
        },
        component: () => import("@/views/main/ClientsView.vue"),
      },
      {
        path: "/logout",
        name: "logout",
        beforeEnter(to, from, next) {
          AuthStore.dispatch("logout")
            .then(() => {
              next({ name: "login" });
            })
            .catch((err) => {
              console.error("Logout failed:", err);
              next();
            });
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/not-authorized",
        name: "not-authorized",
        meta: { requiresAuth: false },
        component: () => import("@/views/main/NotAuthorized.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = AuthStore.state.token;

  if (requiresAuth && !isAuthenticated) {
    next({name: 'login'});
  } else {
    next();
  }
});

export default router;
